<template>
  <div class="vx-row mb-12">
    <div v-bind:class="[detail ? detailShow : '', detailHide]">
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        v-model="cartSalesReturn"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
        multiple
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <!-- <vs-th
            ><vs-checkbox
              v-model="selectAll"
              @input="handleSelectAll"
            />Select</vs-th
          > -->
          <vs-th>View</vs-th>
          <vs-th sort-key="code">Sales Return Code</vs-th>
          <vs-th sort-key="code">Salesman</vs-th>
          <vs-th sort-key="name">Customer Return Data</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td>
              <div class="vx-input-group flex">
                <vs-checkbox
                  v-model="cartSalesReturn"
                  :vs-value="tr"
                  color="success"
                  @input="handleClickCheckBox"
                />
              </div>
            </vs-td> -->
            <vs-td>
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer mr-1 mt-1"
                svgClasses="w-4 h-4"
                @click="viewDetail(tr.sales_return_line, tr)"
              />
            </vs-td>
            <vs-td :data="data[indextr].code">
              {{ data[indextr].code }}
            </vs-td>
            <vs-td :data="data[indextr].sales_personal_name">
              {{ data[indextr].sales_personal_name }}<br />
              {{ data[indextr].sales_personal_mobile }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].zone_code">
            {{ data[indextr].zone_code }}
          </vs-td> -->
            <vs-td :data="data[indextr].customer_name">
              {{ data[indextr].customer_name }}<br />
              {{ data[indextr].customer_code }}<br />
              {{ data[indextr].customer_address }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!--view detail ----->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[detail ? detailShowV2 : '', detailHide]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>

          <!-- form component -->
          <Form
            @close="handleClose"
            :dataViews="dataViews"
            :data="data"
            :isNew="false"
          ></Form>
        </vs-row>
      </div>
    </transition>

    <div class="vx-col">
      <vs-button class="mr-6 mb-2" @click="EndSessionPick" color="danger"
        >Endsession Pickup</vs-button
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Form from "../form.vue";

export default {
  components: {
    Form,
  },
  props: {
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    selectedTerritory: {
      type: Number,
      default: 0,
    },
    selectedWarehouse: {
      type: Number,
      default: 0,
    },
    selectedSalesman: {
      type: Number,
      default: 0,
    },
    selectedCustomer: {
      type: Number,
      default: 0,
    },
    filterActive: {
      type: Boolean,
    },
  },

  mounted() {
    // this.getWarehouse();
    // this.getTerritory();
  },

  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
    return {
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      deleteId: null,
      table: this.tableDefaultState(),
      table2: this.tableDefaultState(),
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      dateNow: moment().format("YYYY-MM-DD"),
      date: tomorrowFormatted,
      cartSalesReturn: [],
      cartTransportPlan: [],
      IDItem: [],
      // dateFilter: moment().format("YYYY-MM-DD"),

      showModal: false,
      appendTransportPlan: [],
      indextrPopup: null,
      dateTp: "",
      dataViews: [],
      data: null,
      detail: false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    checkFilter() {
      console.log(">>>>>ini check filter v2", this.filterActive);
      if (this.filterActive) {
        this.getDatas();
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getDatas();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getDatas();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getDatas();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getDatas();
    },
    viewDetail(item, dt) {
      this.dataViews = [];
      this.data = [];
      this.data = this.detail = true;
      this.dataViews = item;
      this.data = dt;
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
      this.getDatas();
    },
    getDatas() {
      console.log(">>>>>ini get data", this.dateFilter);

      this.table.data = [];
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
      console.log(">>>>", this.$userLogin);
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.selectedTerritory
              ? this.selectedTerritory
              : this.$userLogin.territory_id,
            warehouse_id: this.selectedWarehouse
              ? this.selectedWarehouse
              : this.$userLogin.warehouse_id,
            sales_personal_id: this.selectedSalesman
              ? this.selectedSalesman
              : 0,
            customer_id: this.selectedCustomer ? this.selectedCustomer : 0,
            date_from: this.dateStart,
            date_to: this.dateEnd,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log("rrrrrrr", resp);
            this.table.data = resp.data.records;
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            if (resp.data.records != null) {
              for (let index = 0; index < resp.data.records.length; index++) {
                this.table.data[index]["pickup_date"] = "";
                this.table.data[index]["indextr"] = index;
                this.table.data[index].pickup_date = tomorrowFormatted;
              }
            }
            this.setStartEnd();
            console.log("weee", this.table.data);

            this.$emit("checkFilter", false);

            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartSalesReturn.id);
      this.IDItem = this.cartSalesReturn.map((item) => item.id);
    },

    handleSelectAll(id) {
      this.cartSalesReturn = id ? this.table.data : [];
      this.IDItem = this.cartSalesReturn.map((item) => item.id);
    },
    EndSessionPick() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: `Confirm`,
            text: "Are you sure to EndSession this Data?",
            accept: () => {
              this.endPickup();
            },
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Please complete the form",
            color: "warning",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      });
    },
    endPickup() {
      this.IDItem = this.cartSalesReturn.map((item) => item.id);
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-pickup/close-pickup", {
          sales_return_id: this.IDItem,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "End Pickup Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getDatas();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
  },
  watch: {
    detail() {
      // this.getDatas();
      // this.getTerritory();
    },
    //check if filterActive change
    filterActive: function () {
      this.checkFilter();
    },
  },

  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style>
.vs-popup--background {
  opacity: 0.05;
}

.con-vs-popup .vs-popup {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 100;
  width: 60% !important;
  margin: 10px;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  -webkit-animation: rebound 0.3s;
  animation: rebound 0.3s;
}

.v-data-table .v-data-table__wrapper {
  overflow-y: hidden !important;
}
</style>
