<template>
  <div class="vx-row mb-12">
    <div v-bind:class="[detail ? detailShow : '', detailHide]">
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        v-model="cartSalesReturn"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <!-- <vs-th
                    ><vs-checkbox
                      v-model="selectAll"
                      @input="handleSelectAll"
                    />ALL</vs-th
                  > -->
          <!-- <vs-th>Select</vs-th> -->
          <vs-th>View</vs-th>
          <vs-th sort-key="sales_return_code">Sales Return Code</vs-th>
          <!-- <vs-th sort-key="name">Zone Data</vs-th> -->
          <vs-th sort-key="name">Customer Return Data</vs-th>
          <vs-th sort-key="pickup_date">Pickup Date</vs-th>
          <!-- <vs-th sort-key="pickup_date">Items</vs-th> -->
          <vs-th sort-key="name">Transport Plan Info</vs-th>
          <vs-th sort-key="name">Status</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td>
                <div class="vx-input-group flex">
                  <vs-checkbox
                    v-model="cartSalesReturn"
                    :vs-value="tr.id"
                    color="success"
                    @input="handleClickCheckBox"
                  />
                </div>
              </vs-td> -->
            <vs-td>
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer mr-1 mt-1"
                svgClasses="w-4 h-4"
                @click="viewDetail(tr.item, tr)"
              />
            </vs-td>
            <vs-td :data="data[indextr].sales_return_code">
              {{ data[indextr].sales_return_code }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].zone_code">
              {{ data[indextr].zone_code }}
            </vs-td> -->
            <vs-td :data="data[indextr].customer_name">
              {{ data[indextr].customer_name }}<br />
              {{ data[indextr].customer_code }}<br />
              {{ data[indextr].customer_address }}
            </vs-td>
            <vs-td :data="data[indextr].pickup_date_str">
              {{ data[indextr].pickup_date_str }}
            </vs-td>
            <!-- <vs-td>
              <vs-table
                :style="{ width: '100%' }"
                :sst="false"
                :data="data[indextr].item"
                :total="data[indextr].item.length"
                :searchable="false"
              >
                <template slot="thead">
                  <vs-th>Item Name</vs-th>
                  <vs-th>Quatity</vs-th>
                  <vs-th>Adj Quantity</vs-th>
                </template>
  
                <template slot-scope="{ data }">
                  <vs-tr
                    :data="tk"
                    :key="indextrItem"
                    v-for="(tk, indextrItem) in data"
                  >
                    <vs-td>
                      <b>{{ tk.item_name }}</b>
                    </vs-td>
                    <vs-td>{{ tk.quantity }}</vs-td>
                    <vs-td>{{ tk.adjusted_quantity }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-td> -->
            <vs-td>
              {{
                data[indextr].vehicle_number
                  ? data[indextr].vehicle_number
                  : "MANUAL"
              }}
              <br />
              {{ data[indextr].shipment_number }}
            </vs-td>
            <vs-td>
              <span
                v-if="data[indextr].status == 3"
                class="p-1 rounded-full bg-danger text-white"
                >RE PICKUP</span
              >
            </vs-td>
            <!-- detail delivery orders -->
            <!-- <template slot="expand">
              <div class="vx-row" :style="{ overflow: 'auto' }">
                <div class="vx-col">
                  <vs-table
                    :style="{ width: '100%' }"
                    :sst="false"
                    :data="tr.item"
                    :total="tr.item.length"
                    :searchable="false"
                  >
                    <template slot="thead">
                      <vs-th>Item Name</vs-th>
                      <vs-th>Quatity</vs-th>
                      <vs-th>Adj Quantity</vs-th>
                    </template>
  
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tk"
                        :key="indextrItem"
                        v-for="(tk, indextrItem) in data"
                      >
                        <vs-td>
                          <b>{{ tk.item_name }}</b>
                        </vs-td>
                        <vs-td>{{ tk.quantity }}</vs-td>
                        <vs-td>{{ tk.adjusted_quantity }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </template> -->
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
    <!--view detail ----->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[detail ? detailShowV2 : '', detailHide]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>

          <!-- form component -->
          <Form
            @close="handleClose"
            :dataViews="dataViews"
            :data="data"
            :isNew="true"
          ></Form>
        </vs-row>
      </div>
    </transition>
  </div>
</template>

<script>
import Form from "../form.vue";

export default {
  components: { Form },
  props: {
    dateStart: {
      type: String,
      default: "",
    },
    dateEnd: {
      type: String,
      default: "",
    },
    selectedTerritory: {
      type: Number,
      default: 0,
    },
    selectedWarehouse: {
      type: Number,
      default: 0,
    },
    selectedSalesman: {
      type: Number,
      default: 0,
    },
    selectedCustomer: {
      type: Number,
      default: 0,
    },
    filterActive: {
      type: Boolean,
    },
  },

  mounted() {
    // this.getWarehouse();
    // this.getTerritory();
  },

  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      table2: this.tableDefaultState(),
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      transportPlan: [],
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      date: tomorrowFormatted,
      cartSalesReturn: [],
      cartTransportPlan: [],
      IDItem: [],
      dataViews: [],
      data: null,
      detail: false,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    checkFilter() {
      console.log(">>>>>ini check filter v2", this.filterActive);
      if (this.filterActive) {
        this.getData();
      }
    },
    viewDetail(item, dt) {
      this.dataViews = [];
      this.data = [];
      this.data = this.detail = true;
      this.dataViews = item;
      this.data = dt;
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },

    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/table-repickup", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.selectedTerritory,
            warehouse_id: this.selectedWarehouse,
            salesman_id: this.selectedSalesman,
            customer_id: this.selectedCustomer,
            date_from: this.dateStart,
            date_to: this.dateEnd,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.data = [];
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.$emit("checkFilter", false);
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartSalesReturn.id);
      this.$emit("selected-items", this.cartSalesReturn);
    },
    onClickBtnFilter() {
      this.territoryID = this.selectedTerritory.id;

      this.warehouseID = this.selectedWarehouse.id;

      this.getData();
    },
    ExportForm() {
      this.IDItem = this.cartSalesReturn.map((item) => item.id);
      var fileTitle = "TRANSPORT_PICKUP_EXPORT_";
      // this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-pickup/export", {
          responseType: "arraybuffer",
          params: {
            selectedExport: this.IDItem,
          },
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          // console.log(resp);
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
          // this.$vs.loading.close();
        });
    },
  },
  watch: {
    // detail() {
    //   this.getData();
    //   this.getTerritory();
    // },
    //check if filterActive change
    filterActive: function () {
      this.checkFilter();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
